import React, { useRef, useState } from "react";
import { IClientToolsDownload } from "../../../utility/IClientToolsDownload";

import {
  Card,
  CardBody,
  CardText,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

import { hasOverflow } from "../ClientConnectivityFunctions";
import { showFlyout } from "../../../redux/actions";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { Menu, MenuItem, Paper, Popper } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";

interface IExternalSubMenuDownloadTileProps {
  downloadUrls: IClientToolsDownload;
  idName: string;
  testId?: string;
  imageIcon: string;
  description: string;
  showBetaBadge: boolean;
  internalDoc: string;
  externalDoc?: string;
}

export const ExternalSubMenuDownloadTile = (
  props: IExternalSubMenuDownloadTileProps,
) => {
  const cardRef = useRef(null);
  const dispatch = useAppDispatch();
  const [showToolTip, setShowTooltip] = useState(false);
  const docUrl = props.internalDoc;
  const name = props.description;
  const source = "Clients";

  return (
    <Card
      data-testid={props.testId}
      name={props.idName}
      id={props.idName}
      className="shadow-sm client-tools-card pages-clientConnectivity-ExternalSubMenuDownloadTile"
      onMouseOver={() => setShowTooltip(hasOverflow(cardRef.current))}
      onMouseOut={() => setShowTooltip(false)}
    >
      <div>
        <svg
          className="client-connectivity-icon card-img-top img-thumbnail"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <use xlinkHref={props.imageIcon}></use>
        </svg>
      </div>

      <div>
        <CardBody className="card-body">
          <UncontrolledTooltip
            placement="top"
            target={`title-${props.idName}`}
            isOpen={showToolTip}
          >
            {props.description}
          </UncontrolledTooltip>
          <CardText
            id={`title-${props.idName}`}
            tag="h6"
            className="card-text text-truncate text-muted"
            ref={cardRef}
          >
            {props.description}
          </CardText>
        </CardBody>
      </div>
      <div>
        <div className="action-card-container d-flex">
          <Badge
            id="beta-badge"
            hidden={!props.showBetaBadge}
            color=""
            className="client-tools-beta-badge"
          >
            Beta
          </Badge>
          <a
            rel="noopener noreferrer"
            className="docs-btn"
            onClick={() => dispatch(showFlyout(docUrl, name, source))}
          >
            Docs
          </a>
          <div className="divider" />
          <DownloadDropdown {...props} />
        </div>
      </div>
    </Card>
  );
};

function DownloadDropdown(props: IExternalSubMenuDownloadTileProps) {
  const { downloadUrls } = props;

  // Used to position the dropdown when clicking the Download link on the client tools cards.
  const [downloadDropdownAnchorEl, setDownloadDropdownAnchorEl] =
    useState<null | HTMLElement>(null);
  function onClickDownload(event: React.MouseEvent<HTMLElement>) {
    setDownloadDropdownAnchorEl(event.currentTarget);
  }
  function handleCloseDropdown() {
    setDownloadDropdownAnchorEl(null);
    setSubMenuAnchorEl(null);
  }

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  function onHoverSubMenuItem(event: React.UIEvent<HTMLElement>) {
    setSubMenuAnchorEl(event.currentTarget);
  }
  function closeSubMenu() {
    setSubMenuAnchorEl(null);
  }

  function onClickMenuItem(downloadUrl: string) {
    window.open(downloadUrl, "_blank");
  }

  function onKeyPressSubMenuItem(event: React.KeyboardEvent<HTMLElement>) {
    // Handles navigation by the arrow keys in the sub-menu.
    // Pressing up or down navigates to the previous/next item.
    // Pressing left goes back to the regular menu.
    const allSubMenuItems = Array.from(
      document.querySelectorAll<HTMLElement>(
        ".externalSubMenuDownloadTile-subMenuItem",
      ),
    );
    let currentSelectionIndex = allSubMenuItems.indexOf(
      document.activeElement as HTMLElement,
    );
    if (currentSelectionIndex === -1) {
      currentSelectionIndex = 0;
    }
    if (event.key === "ArrowDown") {
      const nextElement =
        allSubMenuItems[currentSelectionIndex + 1] ?? allSubMenuItems[0];
      nextElement.focus();
    }
    if (event.key === "ArrowUp") {
      const nextElement =
        allSubMenuItems[currentSelectionIndex - 1] ??
        allSubMenuItems[allSubMenuItems.length - 1];
      nextElement.focus();
    }
    if (event.key === "ArrowLeft") {
      document.querySelector<HTMLElement>(".linuxSubMenuItem")?.focus();
    }
  }

  return (
    <>
      <CDataButton
        buttonType={ButtonType.Borderless}
        className="download-btn"
        onClick={onClickDownload}
      >
        Download <i className="fa fa-solid fa-caret-down download-caret"></i>
      </CDataButton>

      <Menu
        anchorEl={downloadDropdownAnchorEl}
        open={downloadDropdownAnchorEl != null}
        onClose={handleCloseDropdown}
        className="externalSubMenuDownloadTile-menu"
        MenuListProps={{
          "aria-label": "Download",
        }}
        // Turn off the focus trap for the menu, we manually focus the sub-menu and this
        // prevents setting focus to the sub-menu
        disableEnforceFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onMouseEnter={closeSubMenu}
          onClick={() => onClickMenuItem(downloadUrls.macUrl!)}
        >
          <a href={downloadUrls.macUrl!} target="_blank" rel="noreferrer">
            <i className="fa-brands fa-apple fa-lg align-middle me-2 no-pointer-event"></i>
            Mac
          </a>
        </MenuItem>
        <MenuItem
          onMouseEnter={closeSubMenu}
          onClick={() => onClickMenuItem(downloadUrls.windowsUrl!)}
        >
          <a href={downloadUrls.windowsUrl!} target="_blank" rel="noreferrer">
            <i className="fa-brands fa-windows fa-lg align-middle me-2 no-pointer-event"></i>
            Windows
          </a>
        </MenuItem>

        {downloadUrls.linuxUrl != null && (
          <MenuItem
            onMouseEnter={closeSubMenu}
            onClick={() => onClickMenuItem(downloadUrls.linuxUrl!)}
          >
            <a href={downloadUrls.linuxUrl} target="_blank" rel="noreferrer">
              <i className="fa-brands fa-linux fa-lg align-middle me-2 no-pointer-event"></i>
              Linux
            </a>
          </MenuItem>
        )}

        {downloadUrls.linuxUrlSubMenu != null && (
          <MenuItem
            className="linuxSubMenuItem"
            onMouseEnter={onHoverSubMenuItem}
            onClick={(event) => {
              onHoverSubMenuItem(event);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === "ArrowRight") {
                onHoverSubMenuItem(event);
                event.stopPropagation();
                event.preventDefault();

                setTimeout(() => {
                  // Focus the first sub menu item if the user clicks or presses enter here.
                  // This is in a timeout so React can re-render.
                  document
                    .querySelector<HTMLElement>(
                      ".externalSubMenuDownloadTile-subMenuItem",
                    )
                    ?.focus();
                }, 0);
              }
            }}
          >
            <i className="fa-brands fa-linux fa-lg align-middle me-2 no-pointer-event"></i>
            Linux
            <i className="fa fa-solid fa-caret-right sub-caret"></i>
          </MenuItem>
        )}
      </Menu>

      {downloadUrls.linuxUrlSubMenu != null && subMenuAnchorEl != null && (
        <Popper
          anchorEl={subMenuAnchorEl}
          open={subMenuAnchorEl != null}
          placement="right-start"
          className="externalSubMenuDownloadTile-subMenuPopper"
        >
          <Paper className="externalSubMenuDownloadTile-subMenuPopper-paper">
            <MenuItem
              className="externalSubMenuDownloadTile-subMenuItem"
              onKeyDown={onKeyPressSubMenuItem}
              onClick={() =>
                onClickMenuItem(downloadUrls.linuxUrlSubMenu!.url64BitDeb)
              }
            >
              <a
                href={downloadUrls.linuxUrlSubMenu.url64BitDeb}
                target="_blank"
                rel="noreferrer"
              >
                64-bit .deb
              </a>
            </MenuItem>

            <MenuItem
              className="externalSubMenuDownloadTile-subMenuItem"
              onKeyDown={onKeyPressSubMenuItem}
              onClick={() =>
                onClickMenuItem(downloadUrls.linuxUrlSubMenu!.url64BitRpm)
              }
            >
              <a
                href={downloadUrls.linuxUrlSubMenu!.url64BitRpm}
                target="_blank"
                rel="noreferrer"
              >
                64-bit .rpm
              </a>
            </MenuItem>
          </Paper>
        </Popper>
      )}
    </>
  );
}
