/* eslint-disable max-lines */
import { IClientToolsDownload } from "../../utility/IClientToolsDownload";
import ClientConnectivityInfo from "./ClientConnectivityInfo";
import { ClientTileType } from "./ClientTileType";
import ClientToolsInfo from "./ClientToolsInfo";
import {
  ModalPromptBodyRow,
  ModalPromptFieldTypes,
} from "./ConnectionTiles/ModalPromptBody";

const baseDocsAddress = "https://cloud.cdata.com/";

export interface IClientDetails {
  idName: string;
  testId?: string;
  description: string;
  category: string;
  PAT?: boolean;
  ODATA?: boolean;
  OpenAPI?: boolean;
  REST?: boolean;
  docsURL?: string;
  externalURL?: string;
  imageIcon: string;
  beta?: boolean;
  modalTitle?: string;
  modalBodyValues?: ModalPromptBodyRow[];
  type: ClientTileType;
  internalDoc?: string;
  downloadUrls?: IClientToolsDownload;
}

export const ClientDetails: IClientDetails[] = [
  /*
   * For ExternalSubMenuDownloadTile cards, the externalURL property provides a download link for the onboarding wizard.
   * It can be removed when the wizard is updated in CLOUD-2223.
   */

  // A
  {
    idName: "",
    testId: "adoDotNet",
    description: "ADO.NET",
    category: "dev",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/ADO.NET-Client.html`,
    internalDoc: `${baseDocsAddress}docs/ADO.NET-Client.html`,
    externalURL:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFRK-A/setup.exe&name=ADO.NET%20Provider%20for%20CData%20Connect&tag=Download-ado",
    imageIcon: "#ADONET",
    beta: false,
    type: ClientTileType.ExternalDownloadTile,
  },
  {
    idName: "airbyteLink",
    testId: "airbyte",
    description: "Airbyte",
    category: "etl",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Airbyte-Client.html`,
    externalURL: `${baseDocsAddress}docs/Airbyte-Client.html`,
    imageIcon: "#airbyte",
    beta: false,
    modalTitle: "Connect from Airbyte (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Database Server",
            value: ClientConnectivityInfo.airbyte.databaseServer,
          },
          { key: "Port", value: ClientConnectivityInfo.airbyte.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "alteryxLink",
    testId: "alteryx",
    description: "Alteryx",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Alteryx-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Alteryx-Client.html`,
    externalURL: ClientToolsInfo.odbc.windows,
    downloadUrls: {
      macUrl: ClientToolsInfo.odbc.mac,
      windowsUrl: ClientToolsInfo.odbc.windows,
      linuxUrlSubMenu: {
        url64BitDeb: ClientToolsInfo.odbc.linux.deb64,
        url64BitRpm: ClientToolsInfo.odbc.linux.rpm64,
      },
    },
    imageIcon: "#Alteryx",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },
  {
    idName: "amazonQuicksightLink",
    testId: "amazonQuicksight",
    description: "Amazon QuickSight",
    category: "bi",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/AmazonQuickSight-Client.html`,
    externalURL: `${baseDocsAddress}docs/AmazonQuickSight-Client.html`,
    imageIcon: "#amazonquicksight",
    beta: false,
    modalTitle: "Connect from Amazon QuickSight (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Database Server",
            value: ClientConnectivityInfo.amazonQuickSight.databaseServer,
          },
          { key: "Port", value: ClientConnectivityInfo.amazonQuickSight.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "aposLink",
    testId: "apos",
    description: "APOS",
    category: "etl",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/APOS-Client.html`,
    externalURL: `${baseDocsAddress}docs/APOS-Client.html`,
    imageIcon: "#Apos",
    beta: false,
    modalTitle: "Connect from APOS (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Connection String",
        value: ClientConnectivityInfo.apos.connectionString,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "appianLink",
    testId: "appian",
    description: "Appian",
    category: "no",
    imageIcon: "#Appian",
    type: ClientTileType.ComingSoonTile,
  },
  {
    idName: "appSheetLink",
    testId: "appSheet",
    description: "AppSheet",
    category: "no",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/AppSheet-Client.html`,
    internalDoc: `${baseDocsAddress}docs/AppSheet-Client.html`,
    externalURL: "",
    imageIcon: "#AppSheet",
    beta: false,
    modalTitle: "Connect from AppSheets (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Server Name",
        value: ClientConnectivityInfo.appSheet.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "appsscriptLink",
    testId: "appsscript",
    description: "Apps Script",
    category: "dev",
    PAT: true,
    ODATA: true,
    REST: true,
    docsURL: `${baseDocsAddress}docs/AppsScript-Client.html`,
    imageIcon: "#AppsScript",
    beta: false,
    modalTitle: "Connect from Apps Script (REST API)",
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "awsGlueLink",
    testId: "awsGlue",
    description: "AWS Glue",
    category: "etl",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/AWSGlue-Client.html`,
    internalDoc: `${baseDocsAddress}docs/AWSGlue-Client.html`,
    externalURL:
      "https://aws.amazon.com/marketplace/pp/prodview-zrpo2rdikze5k?sr=0-1&ref_=beagle&applicationId=AWSMPContessa",
    imageIcon: "#AWSGlue",
    beta: false,
    type: ClientTileType.ExternalDocTile,
  },
  {
    idName: "axiosLink",
    testId: "axios",
    description: "Axios",
    category: "dev",
    PAT: true,
    ODATA: true,
    REST: true,
    docsURL: `${baseDocsAddress}docs/Axios-Client.html`,
    imageIcon: "#Axios",
    beta: false,
    modalTitle: "Connect from Axios (REST API)",
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "azureAnalysisServicesLink",
    testId: "azureAnalysisServices",
    description: "Azure Analysis Services",
    category: "bi",
    imageIcon: "#AzureAnalysisServices",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/AAS-Client.html`,
    externalURL: `${baseDocsAddress}docs/AAS-Client.html`,
    beta: false,
    modalTitle: "Connect from Azure Analysis Services (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Server",
        value: ClientConnectivityInfo.azureAnalysisServices.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "azureDataFactoryLink",
    testId: "azureDataFactory",
    description: "Azure Data Factory",
    category: "etl",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/AzureDataFactory-Client.html`,
    externalURL: `${baseDocsAddress}docs/Azure-Data-Factory.html`,
    imageIcon: "#AzureDataFactory",
    beta: false,
    modalTitle: "Connect from Azure Data Factory (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Server Name",
        value: ClientConnectivityInfo.azureDataFactory.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "azureLogicAppsLink",
    testId: "azureLogicApps",
    description: "Azure Logic Apps",
    category: "no",
    imageIcon: "#AzureLogicApps",
    type: ClientTileType.ComingSoonTile,
  },

  // B
  {
    idName: "bubbleLink",
    testId: "bubble",
    description: "Bubble",
    category: "no",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Bubble-Client.html`,
    externalURL: `${baseDocsAddress}docs/Bubble-Client.html`,
    imageIcon: "#Bubble",
    beta: false,
    modalTitle: "Connect from Bubble (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Connection String",
        value: ClientConnectivityInfo.bubble.connectionString,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },

  // C
  {
    idName: "choreoLink",
    testId: "choreo",
    description: "Choreo",
    category: "dev",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Choreo-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Choreo-Client.html`,
    externalURL: "",
    imageIcon: "#Choreo",
    beta: false,
    type: ClientTileType.DocOnlyTile,
  },
  {
    idName: "clarisLink",
    testId: "claris",
    description: "Claris FileMaker Pro",
    category: "no",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Claris-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Claris-Client.html`,
    externalURL: "",
    imageIcon: "#Claris",
    beta: false,
    type: ClientTileType.DocOnlyTile,
  },
  {
    idName: "cloudDataFusionLink",
    testId: "cloudDataFusion",
    description: "Cloud Data Fusion",
    category: "etl",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/CloudDataFusion-Client.html`,
    internalDoc: `${baseDocsAddress}docs/CloudDataFusion-Client.html`,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    imageIcon: "#cloudDataFusion",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },
  {
    idName: "crystalReports",
    testId: "crystalReports",
    description: "Crystal Reports",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}/docs/CrystalReports-Client.html`,
    internalDoc: `${baseDocsAddress}/docs/CrystalReports-Client.html`,
    externalURL: ClientToolsInfo.jbdcOrIbmCognos.windows,
    imageIcon: "#CrystalReports",
    beta: false,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },

  // D
  {
    idName: "databricks",
    testId: "databricks",
    description: "Databricks",
    category: "dev",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Databricks-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Databricks-Client.html`,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    imageIcon: "#Databricks",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },

  // E
  {
    idName: "excelLink",
    testId: "excel",
    description: "Excel",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Excel365-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Excel365-Client.html`,
    externalURL:
      "https://appsource.microsoft.com/en-us/product/office/WA200004063?tab=Overview",
    imageIcon: "#excel",
    beta: false,
    type: ClientTileType.ExternalDocTile,
  },

  // F
  // G
  {
    idName: "grafanaLink",
    testId: "grafana",
    description: "Grafana",
    category: "dev",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Grafana-Client.html`,
    externalURL: `${baseDocsAddress}docs/Grafana-Client.html`,
    imageIcon: "#Grafana",
    beta: false,
    modalTitle: "Connect from Grafana (Virtual SQL Server)",
    modalBodyValues: [
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Host",
        value: ClientConnectivityInfo.grafana.connectionString,
      },
      { type: ModalPromptFieldTypes.Email, key: "Username" },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "googleSheetsLink",
    testId: "googleSheets",
    description: "Google Sheets",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/GoogleSheets-Client.html`,
    internalDoc: `${baseDocsAddress}docs/GoogleSheets-Client.html`,
    externalURL:
      "https://gsuite.google.com/marketplace/app/cdata_connect_cloud/53950082621",
    imageIcon: "#GoogleSheets",
    beta: false,
    type: ClientTileType.ExternalDocTile,
  },

  // H
  // I
  {
    idName: "ibmCognosLink",
    testId: "ibmCognos",
    description: "IBM Cognos Analytics",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/IBMCognos-Client.html`,
    internalDoc: `${baseDocsAddress}docs/IBMCognos-Client.html`,
    // Remove externalURL when onboarding wizard is updated in CLOUD-2223.
    externalURL: ClientToolsInfo.jbdcOrIbmCognos.windows,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    imageIcon: "#IBMCognos",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },

  // J
  {
    idName: "jdbcLink",
    testId: "jdbc",
    description: "JDBC",
    category: "dev",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/JDBC-Client.html`,
    internalDoc: `${baseDocsAddress}docs/JDBC-Client.html`,
    // Remove externalURL when onboarding wizard is updated in CLOUD-2223.
    externalURL: ClientToolsInfo.jbdcOrIbmCognos.windows,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    imageIcon: "#JDBC",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },
  {
    idName: "jitterbitLink",
    testId: "jitterbit",
    description: "Jitterbit",
    category: "iPaaS",
    PAT: true,
    ODATA: true,
    docsURL: `${baseDocsAddress}docs/Jitterbit-Client.html`,
    imageIcon: "#Jitterbit",
    beta: false,
    modalTitle: "Connect from Jitterbit (OData)",
    type: ClientTileType.ModalPromptTile,
  },

  // L
  {
    idName: "lookerLink",
    testId: "looker",
    description: "Looker",
    category: "bi",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Looker-Client.html`,
    externalURL: `${baseDocsAddress}docs/Looker-Client.html`,
    imageIcon: "#Looker",
    beta: false,
    modalTitle: "Connect from Looker (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Remote Host",
            value: ClientConnectivityInfo.looker.remoteHost,
          },
          { key: "Port", value: ClientConnectivityInfo.looker.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "lookerStudioLink",
    testId: "lookerStudio",
    description: "Looker Studio",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/LookerStudio-Client.html`,
    externalURL:
      "https://datastudio.google.com/data?search=CData%20Connect%20Cloud",
    internalDoc: `${baseDocsAddress}docs/LookerStudio-Client.html`,
    imageIcon: "#LookerStudio",
    beta: false,
    type: ClientTileType.ExternalDocTile,
  },

  // M
  {
    idName: "mendixLink",
    testId: "mendix",
    description: "Mendix",
    category: "no",
    PAT: true,
    ODATA: true,
    docsURL: `${baseDocsAddress}docs/Mendix-Client.html`,
    externalURL: "",
    imageIcon: "#Mendix",
    beta: false,
    modalTitle: "Connect from Mendix (OData)",
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "metabaseLink",
    testId: "metabase",
    description: "Metabase",
    category: "bi",
    imageIcon: "#Metabase",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Metabase-Client.html`,
    externalURL: `${baseDocsAddress}docs/Metabase-Client.html`,
    beta: false,
    modalTitle: "Connect from Metabase (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Host Name",
            value: ClientConnectivityInfo.metabase.hostName,
          },
          { key: "Port", value: ClientConnectivityInfo.metabase.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "modeLink",
    testId: "mode",
    description: "Mode",
    category: "bi",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Mode-Client.html`,
    externalURL: `${baseDocsAddress}docs/Mode-Client.html`,
    imageIcon: "#Mode",
    beta: false,
    modalTitle: "Connect from Mode (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Host Name",
            value: ClientConnectivityInfo.mode.hostName,
          },
          { key: "Port", value: ClientConnectivityInfo.mode.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "msPowerAppLink",
    testId: "msPowerApp",
    description: "Microsoft Power Apps",
    category: "no",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/MicrosoftPowerApps-Client.html`,
    externalURL: `${baseDocsAddress}docs/MicrosoftPowerApps-Client.html`,
    imageIcon: "#MicrosoftPowerApps",
    beta: false,
    modalTitle: "Connect from Microsoft Power Apps (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Server",
        value: ClientConnectivityInfo.microsoftPowerApps.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "msPowerAutomateLink",
    testId: "msPowerAutomate",
    description: "Microsoft Power Automate",
    category: "no",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/MicrosoftPowerAutomate-Client.html`,
    externalURL: `${baseDocsAddress}docs/MicrosoftPowerAutomate-Client.html`,
    imageIcon: "#MicrosoftPowerAutomate",
    beta: false,
    modalTitle: "Connect from Microsoft Power Automate",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Host Name",
            value: ClientConnectivityInfo.microsoftPowerAutomate.hostName,
          },
          {
            key: "Port",
            value: ClientConnectivityInfo.microsoftPowerAutomate.port,
          },
        ],
      },
    ],
    type: ClientTileType.OpenAPIModalPromptTitle,
  },
  {
    idName: "microStrategyLink",
    testId: "microStrategy",
    description: "MicroStrategy",
    category: "bi",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/MicroStrategy-Client.html`,
    externalURL: `${baseDocsAddress}docs/MicroStrategy-Client.html`,
    imageIcon: "#MicroStrategy",
    beta: false,
    modalTitle: "Connect from MicroStrategy (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Host Name",
            value: ClientConnectivityInfo.microStrategy.hostName,
          },
          { key: "Port", value: ClientConnectivityInfo.microStrategy.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "mulesoftLink",
    testId: "mulesoft",
    description: "Mulesoft",
    category: "iPaaS",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Mulesoft-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Mulesoft-Client.html`,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    imageIcon: "#Mulesoft",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },

  // N
  {
    idName: "nintexLink",
    testId: "nintex",
    description: "Nintex",
    category: "no",
    PAT: true,
    ODATA: false,
    beta: false,
    docsURL: `${baseDocsAddress}docs/Nintex-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Nintex-Client.html`,
    externalURL: "",
    imageIcon: "#Nintex",
    modalTitle: "Connect from Nintex (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Database Host",
        value: ClientConnectivityInfo.appSheet.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "nodejsLink",
    testId: "nodejs",
    description: "Node js",
    category: "dev",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Nodejs-Client.html`,
    externalURL: `${baseDocsAddress}docs/Nodejs-Client.html`,
    imageIcon: "#nodejs",
    beta: false,
    modalTitle: "Connect from Node js (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Host Name",
            value: ClientConnectivityInfo.nodejs.hostName,
          },
          { key: "Port", value: ClientConnectivityInfo.nodejs.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  // O
  {
    idName: "odbcLink",
    testId: "odbc",
    description: "ODBC",
    category: "dev",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/ODBC-Client.html`,
    // Remove externalURL when onboarding wizard is updated in CLOUD-2223.
    externalURL: ClientToolsInfo.odbc.windows,
    downloadUrls: {
      macUrl: ClientToolsInfo.odbc.mac,
      windowsUrl: ClientToolsInfo.odbc.windows,
      linuxUrlSubMenu: {
        url64BitDeb: ClientToolsInfo.odbc.linux.deb64,
        url64BitRpm: ClientToolsInfo.odbc.linux.rpm64,
      },
    },
    imageIcon: "#ODBC",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },
  {
    idName: "outSystemsLink",
    testId: "outSystems",
    description: "OutSystems",
    category: "no",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Outsystems-Client.html`,
    externalURL: `${baseDocsAddress}docs/Outsystems-Client.html`,
    imageIcon: "#OutSystems",
    beta: false,
    modalTitle: "Connect from Outsystems (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Server",
        value: ClientConnectivityInfo.outSystems.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },

  // P
  {
    idName: "polyBaseLink",
    testId: "polyBase",
    description: "PolyBase",
    category: "dev",
    imageIcon: "#PolyBase",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Polybase-Client.html`,
    externalURL: `${baseDocsAddress}docs/Polybase-Client.html`,
    beta: false,
    modalTitle: "Connect from Polybase (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Data Source",
        value: ClientConnectivityInfo.polyBase.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "powerBiLink",
    testId: "powerBi",
    description: "Power BI Desktop",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/PowerBIDesktop-Client.html`,
    internalDoc: `${baseDocsAddress}docs/PowerBIDesktop-Client.html`,
    externalURL:
      "https://www.cdata.com/download/getfile.aspx?file=free/RUBJ-A/setup.exe&name=CData%20Connect%20Cloud%20Power%20BI%20Connector&tag=Download-powerbi",
    imageIcon: "#PowerBIDesktop",
    beta: false,
    type: ClientTileType.DocOnlyTile,
  },
  {
    idName: "powerBiOnlineLink",
    testId: "powerBiOnline",
    description: "PowerBI.com",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/PowerBI.com-Client.html`,
    externalURL: "",
    internalDoc: `${baseDocsAddress}docs/PowerBI.com-Client.html`,
    imageIcon: "#PowerBIOnline",
    beta: false,
    modalTitle: "Connect from PowerBI.com (OData)",
    modalBodyValues: [
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "URL",
        value: ClientConnectivityInfo.powerBi.Url,
      },
    ],
    type: ClientTileType.DocOnlyTile,
  },
  {
    idName: "pythonLink",
    testId: "python",
    description: "Python",
    category: "dev",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Python-Client.html`,
    internalDoc: `${baseDocsAddress}docs/Python-Client.html`,
    // Remove externalURL when onboarding wizard is updated in CLOUD-2223.
    externalURL: ClientToolsInfo.python.windows,
    downloadUrls: {
      macUrl: ClientToolsInfo.python.mac,
      windowsUrl: ClientToolsInfo.python.windows,
      linuxUrl: ClientToolsInfo.python.linux,
    },
    imageIcon: "#Python",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },

  // Q
  {
    idName: "qlikSenseLink",
    testId: "qlikSense",
    description: "Qlik Sense",
    category: "bi",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/QlikSenseCloud-Client.html`,
    internalDoc: `${baseDocsAddress}docs/QlikSenseCloud-Client.html`,
    externalURL: `${baseDocsAddress}docs/QlikSenseCloud-Client.html`,
    imageIcon: "#QlikSense",
    beta: false,
    modalTitle: "Connect from Qlik Sense Cloud (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          { key: "Server", value: ClientConnectivityInfo.qlikSense.server },
          { key: "Port", value: ClientConnectivityInfo.qlikSense.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },

  // R
  {
    idName: "redashLink",
    testId: "redash",
    description: "Redash",
    category: "bi",
    imageIcon: "#Redash",
    beta: false,
    PAT: true,
    docsURL: `${baseDocsAddress}docs/Redash-Client.html`,
    modalTitle: "Connect from Redash (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Connection String",
        value: ClientConnectivityInfo.redash.connectionString,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "retoolLink",
    testId: "retool",
    description: "Re-tool",
    category: "no",
    imageIcon: "#Retool",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Retool-Client.html`,
    externalURL: `${baseDocsAddress}docs/Retool-Client.html`,
    beta: false,
    modalTitle: "Connect from ReTool (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Connection String",
        value: ClientConnectivityInfo.retool.connectionString,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },

  // S
  {
    idName: "salesforceCloudLink",
    testId: "salesforceCloud",
    description: "Salesforce Lightning Connect",
    category: "no",
    PAT: true,
    ODATA: true,
    docsURL: `${baseDocsAddress}docs/SFLC-Client.html`,
    externalURL: "",
    imageIcon: "#SalesforceLightningConnect",
    beta: false,
    modalTitle: "Connect from Salesforce Lightning Connect (Odata)",
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "salesforceExternalServicesLink",
    testId: "salesforceExternalServices",
    description: "Salesforce External Services",
    category: "no",
    PAT: true,
    ODATA: true,
    OpenAPI: true,
    docsURL: `${baseDocsAddress}docs/SalesforceExternal-Client.html`,
    externalURL: "",
    imageIcon: "#SalesforceExternalServices",
    beta: false,
    modalTitle: "Connect from Salesforce External Services (OpenAPI)",
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "sapAnalyticsCloudLink",
    testId: "sapAnalyticsCloud",
    description: "SAP Analytics Cloud",
    category: "bi",
    PAT: true,
    ODATA: true,
    docsURL: `${baseDocsAddress}docs/SAPAnalyticsCloud-Client.html`,
    externalURL: "",
    imageIcon: "#SAPAnalyticsCloud",
    beta: false,
    modalTitle: "Connect from SAP Analytics Cloud (OData)",
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "sapLumiraLink",
    testId: "sapLumira",
    description: "SAP Lumira",
    category: "bi",
    imageIcon: "#SAPLumira",
    type: ClientTileType.ComingSoonTile,
  },
  {
    idName: "snapLogic",
    testId: "snapLogic",
    description: "Snaplogic",
    category: "iPaaS",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}/docs/SnapLogic-Client.html`,
    internalDoc: `${baseDocsAddress}/docs/SnapLogic-Client.html`,
    externalURL: ClientToolsInfo.jbdcOrIbmCognos.windows,
    imageIcon: "#Snaplogic",
    beta: false,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },
  {
    idName: "sasViya",
    testId: "sasViya",
    description: "SAS Viya",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}/docs/SASViya-Client.html`,
    internalDoc: `${baseDocsAddress}/docs/SASViya-Client.html`,
    externalURL: ClientToolsInfo.jbdcOrIbmCognos.windows,
    imageIcon: "#SASViya",
    beta: false,
    downloadUrls: {
      macUrl: ClientToolsInfo.jbdcOrIbmCognos.mac,
      windowsUrl: ClientToolsInfo.jbdcOrIbmCognos.windows,
      linuxUrl: ClientToolsInfo.jbdcOrIbmCognos.linux,
    },
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },
  {
    idName: "sqlLinkedServer",
    testId: "sqlLinkedServer",
    description: "SQL Server Linked Server",
    category: "dev",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/SQLServerLinked-Client.html`,
    externalURL: `${baseDocsAddress}docs/SQLServerLinked-Client.html`,
    imageIcon: "#SqlLinkedServer",
    beta: false,
    modalTitle: "Connect from SQL Server Linked Server (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Data Source",
        value: "tds.cdata.com,14333",
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "ssisLink",
    testId: "ssis",
    description: "SSIS",
    category: "dev",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/SSIS-Client.html`,
    internalDoc: `${baseDocsAddress}docs/SSIS-Client.html`,
    externalURL:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFSK-A/setup.exe&name=CData%20Connect%20SSIS%20Components&tag=Download-connect",
    imageIcon: "#SSIS",
    beta: false,
    type: ClientTileType.ExternalDownloadTile,
  },

  // T
  {
    idName: "tableuCloudLink",
    testId: "tableuCloud",
    description: "Tableau Cloud",
    category: "bi",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/TableauCloud-Client.html`,
    externalURL: "",
    imageIcon: "#TableauCloud",
    beta: false,
    modalTitle: "Connect from Tableau Cloud (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.SingleField,
        key: "Server",
        value: ClientConnectivityInfo.tableauCloud.server,
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "tableuDesktopLink",
    testId: "tableuDesktop",
    description: "Tableau Desktop",
    category: "bi",
    PAT: false,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/TableauDesktop-Client.html`,
    internalDoc: `${baseDocsAddress}docs/TableauDesktop-Client.html`,
    // Remove externalURL when onboarding wizard is updated in CLOUD-2223.
    externalURL: ClientToolsInfo.tableauDesktop.windows,
    downloadUrls: {
      macUrl: ClientToolsInfo.tableauDesktop.mac,
      windowsUrl: ClientToolsInfo.tableauDesktop.windows,
      linuxUrl: ClientToolsInfo.tableauDesktop.linux,
    },
    imageIcon: "#TableauDesktop",
    beta: false,
    type: ClientTileType.ExternalSubMenuDownloadTile,
  },
  {
    idName: "thoughtSpotLink",
    testId: "thoughtSpot",
    description: "ThoughtSpot",
    category: "bi",
    imageIcon: "#ThoughtSpot",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/ThoughtSpot-Client.html`,
    externalURL: `${baseDocsAddress}docs/ThoughtSpot-Client.html`,
    beta: false,
    modalTitle: "Connect from ThoughtSpot (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Host Name",
            value: ClientConnectivityInfo.thoughtSpot.hostName,
          },
          { key: "Port", value: ClientConnectivityInfo.thoughtSpot.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },

  // U
  // V
  // W
  {
    idName: "workatoLink",
    testId: "workato",
    description: "Workato",
    category: "no",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/Workato-Client.html`,
    externalURL: `${baseDocsAddress}docs/Workato-Client.html`,
    imageIcon: "#workato",
    beta: false,
    modalTitle: "Connect from Workato (Virtual SQL Server)",
    modalBodyValues: [
      {
        type: ModalPromptFieldTypes.Email,
        key: "Username",
      },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Host Name",
            value: ClientConnectivityInfo.workato.hostName,
          },
          {
            key: "Port",
            value: ClientConnectivityInfo.workato.port,
          },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
  // X
  // Y
  // Z
  {
    idName: "zapierLink",
    testId: "zapier",
    description: "Zapier",
    category: "iPaaS",
    PAT: true,
    ODATA: true,
    docsURL: `${baseDocsAddress}docs/Zapier-Client.html`,
    imageIcon: "#Zapier",
    beta: false,
    modalTitle: "Connect from Zapier (OData)",
    type: ClientTileType.ModalPromptTile,
  },
  {
    idName: "zohoAnalyticsLink",
    testId: "zohoAnalytics",
    description: "Zoho Analytics",
    category: "bi",
    PAT: true,
    ODATA: false,
    docsURL: `${baseDocsAddress}docs/ZohoAnalytics-Client.html`,
    externalURL: `${baseDocsAddress}docs/ZohoAnalytics-Client.html`,
    imageIcon: "#zohoAnalytics",
    beta: false,
    modalTitle: "Connect from Zoho Analytics (Virtual SQL Server)",
    modalBodyValues: [
      { type: ModalPromptFieldTypes.Email, key: "Username" },
      {
        type: ModalPromptFieldTypes.MultiField,
        children: [
          {
            key: "Server Name",
            value: ClientConnectivityInfo.zohoAnalytics.serverName,
          },
          { key: "Port", value: ClientConnectivityInfo.zohoAnalytics.port },
        ],
      },
    ],
    type: ClientTileType.ModalPromptTile,
  },
];
