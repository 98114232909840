const ClientToolsInfo = {
  tableauDesktop: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFTJ-V/setup.exe&name=Tableau%20Connector%20for%20CData%20Connect&tag=Download-tableau",
    linux:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFTJ-V/connector.zip&name=Tableau%20Connector%20for%20CData%20Connect&tag=Download-tableau",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFTJ-V/setup.dmg&name=Tableau%20Connector%20for%20CData%20Connect&tag=Download-tableau",
  },

  odbc: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFDK-A/setup.exe&name=CData%20Connect%20ODBC%20Driver&tag=Download-connect",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFDK-M/setup.dmg&name=CData%20Connect%20ODBC%20Driver%20(Mac)&tag=Download-connect",
    linux: {
      deb64:
        "https://www.cdata.com/download/getfile.aspx?file=free/LFDK-U/setup.x86_64.deb&name=CData%20Connect%20ODBC%20Driver%20(Unix)&tag=Download-connect",
      rpm64:
        "https://www.cdata.com/download/getfile.aspx?file=free/LFDK-U/setup.x86_64.rpm&name=CData%20Connect%20ODBC%20Driver%20(Unix)&tag=Download-connect",
    },
  },

  python: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFVK-X/setup_win.zip&name=CData%20Connect%20Python%20Connector&tag=Download-connect",
    linux:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFVK-X/setup_unix.zip&name=CData%20Connect%20Python%20Connector&tag=Download-connect",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFVK-X/setup_mac.zip&name=CData%20Connect%20Python%20Connector&tag=Download-connect",
  },

  // As per docs these 2 client cards share the same download links.
  jbdcOrIbmCognos: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFRK-V/setup.exe&name=CData%20Connect%20JDBC%20Driver&tag=Download-connect",
    linux:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFRK-V/setup.zip&name=CData%20Connect%20JDBC%20Driver&tag=Download-connect",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFRK-V/setup.dmg&name=CData%20Connect%20JDBC%20Driver&tag=Download-connect",
  },
};

export default ClientToolsInfo;
